<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Orden de Compra - Editar</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>
   

                <b-col md="6">
                  <b-form-group>
                    <label>Proveedor: <span @click="modalProviders" class="text-info link">Nuevo</span></label>
                    <v-select placeholder="Seleccione un proveedor" class="w-100" :filterable="false" label="name" v-model="provider" @search="SearchProvider" :options="providers"></v-select>
                    <small v-if="errors.id_provider" class="form-text text-danger" >Selccione un proveedor</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Numero :">
                    <b-form-input disabled class="text-right" type="text" ref="number" v-model="purchase_order.number"></b-form-input>
                    <small v-if="errors.number" class="form-text text-danger">Ingrese un numero de 8 digitos</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha Emision:">
                    <b-form-input type="date" ref="broadcast_date" v-model="purchase_order.broadcast_date"></b-form-input>
                    <small v-if="errors.broadcast_date" class="form-text text-danger">Seleccione una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                   <b-form-group label=".">
                    <b-button :disabled="purchase_order.linkages.length > 0" class="form-control btn" variant="primary" @click="modalProducts" ><i class="fas fa-cart-plus"></i> Productos (F2)</b-button>
                   </b-form-group>
                </b-col>



                 <b-col md="3">
                  <b-form-group label="Forma de Pago :">
                    <b-form-select ref="way_to_pay" v-model="purchase_order.way_to_pay" :options="way_to_pay"></b-form-select>
                    <small v-if="errors.way_to_pay" class="form-text text-danger">Seleccione una forma de pago</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Moneda:">
                    <b-form-select ref="coin" v-model="purchase_order.coin" :options="coins"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group>
                    <label for="">Tipo de Cambio :</label>
                    <b-form-input class="text-right" type="number"  v-model="purchase_order.exchange_rate"></b-form-input>
                    <small v-if="errors.exchange_rate" class="form-text text-danger">Ingrese un tipo de cambio</small>
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label="Almacen :">
                    <b-form-select :disabled="purchase_order.linkages.length > 0" ref="id_warehouse" v-model="purchase_order.id_warehouse" :options="warehouse"></b-form-select>
                    <small v-if="errors.id_warehouse" class="form-text text-danger">Seleccione un almacen</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Valor Unitario:">
                    <b-form-select ref="unit_value" @change="ChangeUnitValue" v-model="purchase_order.unit_value" :options="unit_value"></b-form-select>
                  </b-form-group>
                </b-col>


                <b-col md="12">
                  <b-form-group label="Observación:">
                    <b-form-input rows="1"  v-model="purchase_order.observation" max-rows="3"></b-form-input>
                  </b-form-group>
                </b-col>

           

                <!-- Detalle Entrada -->
                <mShoppingDetail/>
                <small v-if="errors.purchase_order_detail" class="col-md-12 form-text text-center text-danger">Agregue productos</small>
                <!-- Detalle Entrada -->
                <b-col md="12" class="mt-3"></b-col>


                <b-col md="8">
                  <b-row>
                     <b-col md="6">
                       <div class="table-responsive mt-3">
                        <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                          <thead >
                            <tr>
                              <th  width="20%" class="text-center">Fecha</th>
                              <th  width="70%" class="text-center">Order de Pedido</th>
                            
                            </tr>
                          </thead>
                          <tbody v-for="(item, it) in purchase_order.linkages" :key="it">
                            <tr>
                              <td class="text-center">{{ item.broadcast_date }}</td>
                              <td class="text-center">{{ item.reference  }}</td>
    
                            </tr>
                          </tbody>
                        </table>
                       </div>
                     </b-col>
                   </b-row>
                </b-col>
                <b-col md="4">
                  <b-form-group  label-cols-sm="7" label="Ope. Gravadas :" class="text-right m-0">
                    <b-form-input class="text-right" type="number" step="any" readonly v-model="total_shopping.taxed_operation"></b-form-input>
                  </b-form-group>
                  <b-form-group label-cols-sm="7" label="Ope. Exonerada :" class="text-right m-0">
                    <b-form-input class="text-right" type="number" step="any" readonly v-model="total_shopping.exonerated_operation"></b-form-input>
                  </b-form-group>
                  <b-form-group  label-cols-sm="7" label="Ope. Inafecta :" class="text-right m-0">
                    <b-form-input class="text-right" type="number" step="any" readonly v-model="total_shopping.unaffected_operation"></b-form-input>
                  </b-form-group>
                  <b-form-group  label-cols-sm="7" label="Descuentos :" class="text-right m-0">
                    <b-form-input class="text-right" type="number" step="any" readonly v-model="total_shopping.discount"></b-form-input>
                  </b-form-group>
                  <b-form-group label-cols-sm="7" label="IGV (18%) :" class="text-right m-0">
                    <b-form-input class="text-right" type="number" step="any" readonly v-model="total_shopping.igv"></b-form-input>
                  </b-form-group>
                  <b-form-group label-cols-sm="7" label="Total :" class="text-right m-0">
                    <b-form-input class="text-right" type="number" step="any" readonly v-model="total_shopping.total"></b-form-input>
                  </b-form-group>
                </b-col>
                

                <b-col md="5">
                </b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>

              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- Modal Products -->
    <ModalProducts />
    <!-- Modal Clients -->
    <ModalProviders />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="113" @success="modalProducts" />
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>
///import vue select
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import CodeToName from "@/assets/js/CodeToName";
// components
import ModalProviders from '@/views/components/ModalProvider'
import ModalProducts from './components/ModalProduct'
import mShoppingDetail from './components/ShoppingDetail'
import LoadingComponent from './../pages/Loading'
import ModalExchangeRate from '@/views/components/ModalExchangeRate'

export default {
  name: "ShoppingEdit",
  props: ["id_purchase_order"],
  components:{
      vSelect,
      ModalProducts,
      mShoppingDetail,
      ModalProviders,
      LoadingComponent,
      ModalExchangeRate,
      Keypress: () => import('vue-keypress'),
  },
  data() {
    return {
      isLoading:false,
      module: 'PurchaseOrder',
      role: 3,

      purchase_order: {
        id_purchase_order:'',
        id_provider:'',
        id_user:'',
        id_warehouse:'',
        linkages:[],
        operation_type:'02',
        type_invoice:'01',
        serie:'',
        number:'',
        broadcast_date:moment(new Date()).local().format("YYYY-MM-DD"),
        arrival_date:moment(new Date()).local().format("YYYY-MM-DD"),
        coin:'PEN',
        reference:'',
        exchange_rate: '1.00',
        way_to_pay: '01-008',
        payment_type:'01',
        payment_method:'008',
        payment_deadline:'',
        affection_for_detraction: '',
        unit_value: '0',
        observation:'',
        expenses:(0).toFixed(2),
        taxed_operation: (0).toFixed(2),
        exonerated_operation: (0).toFixed(2),
        unaffected_operation: (0).toFixed(2),
        discount: (0).toFixed(2),
        subtotal: (0).toFixed(2),
        igv: (0).toFixed(2),
        total: (0).toFixed(2),
        state:1,
        shopping_detail: [],

      },

      providers: [],
      provider:null,

      warehouse:[],
      coins:[
        {value: "PEN", text : "Soles"},
        {value: "USD", text : "Dolares"},
      ],

       way_to_pay:[
        {value:"01-008",text:'Contado - Efectivo'},
        {value:"01-009",text:'Contado - Efectivo en los demás casos'},
        {value:"01-001",text:'Contado - Depósito en cuenta'},
        {value:"01-002",text:'Contado - Giro'},
        {value:"01-003",text:'Contado - Transferencia de fondos'},
        {value:"01-004",text:'Contado - Orden de pago'},
        {value:"01-005",text:'Contado - Tarjeta de Débito'},
        {value:"03-7",text:'Crédito - 7 dias'},
        {value:"03-15",text:'Crédito - 15 dias'},
        {value:"03-30",text:'Crédito - 30 dias'},
        {value:"03-45",text:'Crédito - 45 dias'},
        {value:"03-60",text:'Crédito - 60 dias'},
        {value:"03-75",text:'Crédito - 75 dias'},
        {value:"03-90",text:'Crédito - 90 dias'},
      ],


      unit_value: [
        { value: '0',text:'Sin IGV'},
        { value: '1',text:'Con IGV'},
        
      ],
      //errors
      errors: {
        id_provider: false,
        serie:false,
        number:false,
        broadcast_date:false,
        arrival_date:false,
        shopping_detail:false,
        exchange_rate:false,
        id_warehouse:false,
        way_to_pay:false,
        affection_for_detraction:false,
        purchase_order_detail:false,
      },
      validate: false,
    };
  },
  mounted() {
    this.mLoadResetShoppingDetail();
    this.ListWarehouse();
    this.ViewPurchaseOrder();
    
 
  },
  methods: {
    ListWarehouse,
    ViewPurchaseOrder,
    SearchProvider,
    modalProducts,
    modalProviders,
    modalExachangeRate,
    NumberPadStart,
    UpperCase,
    EditPurchaseOrder,
    Validate,
    CodeInvoice,
    NameUnitMeasure,

    ChangeUnitValue,
    ChangeExpensesValue, 
    
    Export,
  

    ...mapActions('ShoppingOrder',['mLoadUnitValue','mLoadExpensesValue']),
    ...mapActions('ShoppingOrder',['mLoadResetShoppingDetail']),
    ...mapActions('ShoppingOrder',['mLoadAddShoppingDetail']),
    ...mapActions('ShoppingOrder',['mLoadTotalsShoppingDetail']),
    
  },

  computed: {
    ...mapState('ShoppingOrder',['shopping_detail','total_shopping']),
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
    id_establishment: function () {
      let establishment = window.localStorage.getItem("id_establishment");
      establishment = JSON.parse(je.decrypt(establishment));
      return establishment;
    }
  },
};

function ChangeUnitValue() {
  this.mLoadUnitValue(this.purchase_order.unit_value);
}
function CodeInvoice(code) {
  return CodeToName.CodeInvoice(code);
}
function NameUnitMeasure(code) {
  return CodeToName.NameUnitMeasure(code);
}
function ChangeExpensesValue() {
  this.mLoadExpensesValue(this.purchase_order.expenses);
  this.purchase_order.expenses = parseFloat(this.purchase_order.expenses).toFixed(2);
  this.mLoadTotalsShoppingDetail();
}

function modalExachangeRate() {
  EventBus.$emit('ModalExchangeRateShow');
}

function Export() {
  let id_purchase_order = je.decrypt(this.id_purchase_order);
  let url = this.url_base + "shopping-excel/"+id_purchase_order;
  window.open(url,'_blank');
}


function ListWarehouse() {
  let me = this;
  let url = this.url_base + "warehouse/list-active";
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: this.role,},
  })
    .then(function (response) {
      me.warehouse.push({value: '', text: '-- Seleccione un almacen --'});
      if (response.data.status == 200) {
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.warehouse.push({value: element.id_warehouse, text: element.name});
        }
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
}
function ViewPurchaseOrder() {

  let id_purchase_order = je.decrypt(this.id_purchase_order);
  let me = this;
  let url = this.url_base + "purchase-order/view/" + id_purchase_order;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: this.role, },
  })
    .then(function (response) {
      if (response.data.status == 200) {

        
          me.purchase_order.way_to_pay = response.data.result.purchase_order.way_to_pay;
          me.purchase_order.id_purchase_order = response.data.result.purchase_order.id_purchase_order;
          me.purchase_order.id_provider = response.data.result.purchase_order.id_provider;
          me.purchase_order.id_warehouse = response.data.result.purchase_order.id_warehouse;
          me.purchase_order.id_user = response.data.result.purchase_order.id_user;
          me.purchase_order.linkages = response.data.result.purchase_order.linkages;
          me.purchase_order.number = response.data.result.purchase_order.number;
          me.purchase_order.broadcast_date = response.data.result.purchase_order.broadcast_date;
          me.purchase_order.coin = response.data.result.purchase_order.coin;
          me.purchase_order.exchange_rate = response.data.result.purchase_order.exchange_rate;
          me.purchase_order.unit_value = response.data.result.purchase_order.unit_value;
          me.purchase_order.payment_type = response.data.result.purchase_order.payment_type;
          me.purchase_order.payment_method = response.data.result.purchase_order.payment_method;
          me.purchase_order.payment_deadline = response.data.result.purchase_order.payment_deadline;
          me.purchase_order.observation = response.data.result.purchase_order.observation;
          me.purchase_order.taxed_operation = response.data.result.purchase_order.taxed_operation;
          me.purchase_order.exonerated_operation = response.data.result.purchase_order.exonerated_operation;
          me.purchase_order.unaffected_operation = response.data.result.purchase_order.unaffected_operation;
          me.purchase_order.discount = response.data.result.purchase_order.discount;
          me.purchase_order.subtotal = response.data.result.purchase_order.subtotal;
          me.purchase_order.igv = response.data.result.purchase_order.igv;
          me.purchase_order.total = response.data.result.purchase_order.total;
          me.purchase_order.state = response.data.result.purchase_order.state;
          me.provider = {id: response.data.result.purchase_order.id_provider, name: response.data.result.purchase_order.name+" - "+ response.data.result.purchase_order.document_number};
          me.mLoadUnitValue(me.purchase_order.unit_value);
          //agregar detalle
          let details = response.data.result.purchase_order_detail; 
          for (let index = 0; index < details.length; index++) {
            
              let detail = {
                id_product : details[index].id_product,
                code : details[index].code,
                name : details[index].name + (details[index].presentation.length == 0 ? '':' - '+details[index].presentation),
                unit_measure :  me.NameUnitMeasure(details[index].unit_measure),
                igv : details[index].igv,
                quantity : parseFloat(details[index].quantity).toFixed(2),
                percentage_discount : parseFloat(details[index].percentage_discount).toFixed(2),
                package : parseFloat(details[index].package).toFixed(0),
                unit_value : parseFloat(details[index].unit_value).toFixed(5),
                unit_discount : parseFloat(details[index].unit_discount).toFixed(5),
                net_unit_value : parseFloat(details[index].net_unit_value).toFixed(5),
                unit_igv : parseFloat(details[index].unit_igv).toFixed(5),
                unit_price : parseFloat(details[index].unit_price).toFixed(5),
                total_value : parseFloat(details[index].total_value).toFixed(5),
                total_discount : parseFloat(details[index].total_discount).toFixed(5),
                net_total_value : parseFloat(details[index].net_total_value).toFixed(5),
                total_igv : parseFloat(details[index].total_igv).toFixed(5),
                total_price : parseFloat(details[index].total_price).toFixed(5),
              }
      
              
              me.mLoadAddShoppingDetail(detail);

          }
      } else {
        Swal.fire("Sistema", "A Ocurrido un error", "error");
      }

      me.isLoading = false;
    })
}

function SearchProvider(search, loading) {
  
    let me = this;
    let url = this.url_base + "search-providers/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.providers = response.data.result;
            loading(false);
      })
    }
    
}

function modalProducts() {
  EventBus.$emit('ModalProductsShow',this.role);
}

function modalProviders() {
  EventBus.$emit('ModalProvidersShow');
}


function NumberPadStart() {
  var number = String(this.purchase_order.number);
  number = number.padStart(8,"0");
  this.purchase_order.number = number;
}
function UpperCase() {
    this.purchase_order.serie = this.purchase_order.serie.toUpperCase();
}



function EditPurchaseOrder() {

  let me = this;
  me.isLoading = true;
  let url = me.url_base + "purchase-order/edit";
  me.purchase_order.id_provider = me.provider.id;
  me.purchase_order.taxed_operation = me.total_shopping.taxed_operation;
  me.purchase_order.exonerated_operation = me.total_shopping.exonerated_operation;
  me.purchase_order.unaffected_operation = me.total_shopping.unaffected_operation;
  me.purchase_order.discount = me.total_shopping.discount;
  me.purchase_order.subtotal = me.total_shopping.subtotal;
  me.purchase_order.igv = me.total_shopping.igv;
  me.purchase_order.total = me.total_shopping.total;
  me.purchase_order.purchase_order_detail = me.shopping_detail;
  let data = me.purchase_order;
  axios({
    method: "put",
    url: url,
    data: data,
    headers: {  "Content-Type": "application/json",  token: me.token, module: me.module,role: me.role,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000, })
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000, })
      }
      me.isLoading = false;
    })

}

function Validate() {

  this.errors.id_provider = this.purchase_order.id_provider.length  ? true : false;
  this.errors.number = this.purchase_order.number.length == 0 ? true : false;
  this.errors.broadcast_date = this.purchase_order.broadcast_date.length == 0 ? true : false;
  this.errors.id_warehouse = this.purchase_order.id_warehouse.length == 0 ? true : false;
  this.errors.exchange_rate = this.purchase_order.exchange_rate.length == 0 ? true : false;
  this.errors.purchase_order_detail = this.shopping_detail.length == 0 ? true : false;
  

  if (this.errors.id_provider == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.number == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.broadcast_date == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.exchange_rate == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.id_warehouse == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.purchase_order_detail == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }


  
  let me = this;

  Swal.fire({
    title: 'Esta seguro de modificar la orden de compra?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Estoy de Acuerdo!'
  }).then((result) => {
    if (result.isConfirmed) {
      this.EditPurchaseOrder();
    }
  })


}


</script>
